import React from 'react'
import "./DigitalMenu.css";
import DigitalImage from "./assets/menu-board-product.webp";
const DigitalMenu = ()=> {
    return (
        <div className="digital-menu-container">
          <div className="content">
            <h2 className="headline">Create a Digital Menu for Your Restaurant</h2>
            <p className="description">
              Revolutionize the way you serve your customers with a modern, digital menu. A digital menu makes ordering easier, improves customer experience, and allows you to update your offerings in real-time.
            </p>
            <ul className="benefits-list">
              <li>📱 Easy access on any device</li>
              <li>🔄 Update items, prices, and specials instantly</li>
              <li>💡 Enhance the customer experience</li>
              <li>🌐 Expand your restaurant's reach online</li>
            </ul>
            <p className="cta-text">
              Ready to go digital? Let’s create your custom digital menu today!
            </p>
            <a href='/contact' className="cta-button">Get a Quote</a>
          </div>
          <div className="image">
            <img
              src={DigitalImage}
              alt="Digital Menu"
              className="menu-image"
            />
          </div>
        </div>
      );
}

export default DigitalMenu;