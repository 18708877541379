// src/Home.js
import React from 'react';
import foodMenu from './assets/menu-board-product.webp';
import foodMenuText from './assets/menu-text.webp';
import dataEntryImg from './assets/data-entry.webp';
import webImage from './assets/website.webp';
import {Link} from 'react-router-dom';

const imageWidth = '80%';
const imageHeight = 'auto';

const Home = () => {
  return (
    <div className="home-content">
      {/* Main Banner */}
      <div className="banner"></div>

      {/* Section: Introduction */}
      <div className="image-text">
        <h1>Empowering Your Business with Tailored Solutions: Digital Menus, Digital Signage, Websites, Data Entry, and Custom Applications to Drive Efficiency and Growth</h1>
      </div>

      {/* Section: About Us */}
      <div className="banner-text-container">
        <div className="banner-text-content">
          <h2>At Tiktok Software, We Specialize in Customized Software Solutions</h2>
          <p>
            With a proven track record and a team of experts, we turn your ideas into bespoke software that delivers real results.
          </p>
        </div>
      </div>

      {/* Section: Services */}
      <div className="home-cart-wrapper">
        <div className="home-cart-container">
          
          {/* Service 1: Interactive Digital Menu */}
          <div className="cart">
            <div className="cart-content">
              <h3>Interactive Digital Restaurant Menu with Stunning Food Images</h3>
              <img 
                src={foodMenu} 
                alt="Interactive digital restaurant menu with food images" 
                width={imageWidth} 
                height={imageHeight} 
                className="service-image" 
                loading='lazy'
              />
            </div>
          </div>

          {/* Service 2: Custom Text-Based Menu */}
          <div className="cart">
            <div className="cart-content">
              <h3>Fully Customizable Text-Based Digital Menu for a Clean, Minimalist Look</h3>
              <img 
                src={foodMenuText} 
                alt="Text-based customizable digital menu" 
                width={imageWidth} 
                height={imageHeight} 
                className="service-image" 
                loading='lazy'
              />
            </div>
          </div>

          {/* Service 3: Data Entry Solutions */}
          <div className="cart">
            <div className="cart-content">
              <h3>Efficient, Custom Data Entry Solutions to Streamline Your Business Operations</h3>
              <img 
                src={dataEntryImg} 
                alt="Custom data entry solutions for business operations" 
                width={imageWidth} 
                height={imageHeight} 
                className="service-image" 
                loading='lazy'
              />
            </div>
          </div>

          {/* Service 4: Website Development */}
          <div className="cart">
            <div className="cart-content">
              <h3>
                Custom Websites that Captivate, Convert, and Fuel Business Growth.{' '}
                {/* <a 
                  href="https://www.qualitymart.com.au/shop?Gender=Women" 
                  style={{ fontSize: '14px' }} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Example of our work
                </a> */}
              </h3>
              <img 
                src={webImage} 
                alt="Custom website design for business growth" 
                width={imageWidth} 
                height={imageHeight} 
                className="service-image"
                loading='lazy'
              />
            </div>
          </div>
        </div>
          <Link to='/contact' className='contactbtn'>Contact Us Now</Link>
      </div>
    </div>
  );
};

export default Home;
